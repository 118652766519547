<script>
import FeUserNew from './FeUserNew'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'FeUserEdit',
  extends: FeUserNew,
  methods: {
    initFields () {

    },
    getFeUser () {
      this.$store.dispatch('feUser/fetchOne', this.$route.params.id)
        .then(() => {
          this.feUser = this.$store.getters['feUser/detail']
          this.initFields()
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('feUser.error.required_fields'))
        return
      }
      this.$store.dispatch('feUser/update', this.prepareFeUserRequest(this.feUser))
        .then(() => {
          if (this.$store.getters['feUser/error'] === null) {
            this.getFeUser()
            NotifyService.setSuccessMessage(this.$t('feUser.message.updated_record'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['feUser/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getFeUser()
  }
}
</script>
